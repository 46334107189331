// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toast_fadeToast__1BNt- {\n  animation: Toast_fade__2hjJQ 10s;\n  left: -110%;\n}\n\n@keyframes Toast_fade__2hjJQ {\n  0%, 100% {left: -110%;}\n  5%, 95% {left: 0%;}\n}\n", "",{"version":3,"sources":["webpack://src/components/Toast/Toast.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAmB;EACnB,WAAW;AACb;;AAEA;EACE,UAAU,WAAW,CAAC;EACtB,SAAS,QAAQ,CAAC;AACpB","sourcesContent":[".fadeToast {\n  animation: fade 10s;\n  left: -110%;\n}\n\n@keyframes fade {\n  0%, 100% {left: -110%;}\n  5%, 95% {left: 0%;}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fadeToast": "Toast_fadeToast__1BNt-",
	"fade": "Toast_fade__2hjJQ"
};
export default ___CSS_LOADER_EXPORT___;
